import React, { ComponentProps, ReactNode } from 'react';
import cx from 'classnames';
import { hoverAnimationClassNames } from 'utils/hoverAnimationClassNames';
import Icon from '../Icon/Icon';
import styles from './Button.module.css';
import Spinner, { TSpinnerProps } from '../Spinner/Spinner';

export interface ButtonProps extends ComponentProps<'button'> {
  title?: string;
  variant: 'primary' | 'secondary' | 'lens' | 'success' | 'ghost';
  showRightArrow?: boolean;
  arrowLeft?: ReactNode;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  rounded?: boolean | '2xl' | '4xl';
  isLoading?: boolean;
  spinnerProps?: TSpinnerProps;
  outlined?: boolean;
  clickable?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  title,
  variant,
  showRightArrow,
  arrowLeft,
  disabled,
  className,
  children,
  type = 'button',
  rounded = false,
  isLoading = false,
  spinnerProps,
  outlined,
  clickable = true,
  fullWidth = false,
  ...props
}) => {
  const { size = '8px', color } = spinnerProps || {};
  return (
    <button
      data-testid="Button"
      type={type}
      className={cx(
        'relative flex items-center font-secondary',
        'py-2 px-3 text-xs leading-5 md:text-base md:leading-6',
        className,
        {
          [hoverAnimationClassNames]: !outlined,
          [styles[variant]]: !outlined,
          [styles[`${variant}-outlined`]]: outlined,
          'flex flex-row gap-x-4 items-center': showRightArrow || !!arrowLeft,
          'pointer-events-none': !clickable,
          'w-max': !fullWidth,
          'w-full': fullWidth,
        },
        {
          'rounded-[12px]': !rounded,
          'rounded-4xl': rounded && (typeof rounded === 'boolean' || rounded === '4xl'),
          'rounded-2xl': rounded && rounded === '2xl',
        },
      )}
      disabled={disabled}
      {...props}
    >
      {isLoading && (
        <div className="absolute flex left-1/2 -translate-x-1/2">
          <Spinner size={size} color={color} />
        </div>
      )}
      <div
        className={cx({
          'flex flex-row gap-x-4 items-center': showRightArrow || !!arrowLeft,
          invisible: isLoading,
          visible: !isLoading,
        })}
      >
        {!!arrowLeft && arrowLeft}
        {title || children}
        {showRightArrow && <Icon type="ArrowRight" />}
      </div>
    </button>
  );
};
export default Button;
