var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/api/monitoring/sentry";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9yhvTOE5kiXbkDs-exvNk"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { ENV, SENTRY_DSN } from './config';

Sentry.init({
  environment: ENV,
  dsn: SENTRY_DSN,
  // TODO: Adjust tracesSampleRate in production
  tracesSampleRate: 0.2,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /(http|https):\/\/(.*).t2.world/, /(http|https):\/\/(.*).up.railway.app/],
    }),
  ],
});
