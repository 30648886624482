import { createConfig } from '@privy-io/wagmi';
import { http } from 'wagmi';
import {
  arbitrum,
  base,
  baseSepolia,
  optimism,
  polygon,
  polygonAmoy,
  sepolia,
  zkSync,
  zora,
  zoraSepolia,
  mainnet,
  bsc,
  Chain,
} from 'wagmi/chains';
import { QueryClient } from '@tanstack/react-query';
import { isProductionEnv } from '../../../config';

export const queryClient = new QueryClient();

export const chains: readonly [Chain, ...Chain[]] = isProductionEnv
  ? [arbitrum, mainnet, bsc, base, optimism, polygon, zora, zkSync]
  : [polygonAmoy, zoraSepolia, sepolia, baseSepolia];

export const wagmiConfig = createConfig({
  chains,
  transports: isProductionEnv
    ? {
        [arbitrum.id]: http(),
        [mainnet.id]: http(),
        [bsc.id]: http(),
        [base.id]: http(),
        [optimism.id]: http(),
        [polygon.id]: http(),
        [zora.id]: http(),
        [zkSync.id]: http(),
      }
    : {
        [polygonAmoy.id]: http(),
        [sepolia.id]: http(),
        [zoraSepolia.id]: http(),
        [baseSepolia.id]: http(),
      },
});
