'use client';

import { FC, ReactNode } from 'react';
import { PrivyProvider as OriginalPrivyProvider } from '@privy-io/react-auth';
import {
  arbitrum,
  base,
  baseSepolia,
  bsc,
  mainnet,
  optimism,
  polygon,
  polygonAmoy,
  sepolia,
  zkSync,
  zora,
  zoraSepolia,
} from 'viem/chains';
import { isProductionEnv } from '../../../../config';

type TPrivyProviderProps = {
  appId: string;
  children: ReactNode;
};

export const PrivyProvider: FC<TPrivyProviderProps> = ({ appId, children }) => (
  <OriginalPrivyProvider
    appId={appId}
    config={{
      defaultChain: isProductionEnv ? polygon : polygonAmoy,
      supportedChains: isProductionEnv
        ? [polygon, arbitrum, mainnet, bsc, base, optimism, zora, zkSync]
        : [polygonAmoy, zoraSepolia, sepolia, baseSepolia],
      embeddedWallets: {
        createOnLogin: 'users-without-wallets',
      },
      appearance: {
        theme: '#F9F9F9',
        accentColor: '#929292',
      },
      legal: {
        termsAndConditionsUrl:
          'https://t2world.notion.site/t2world/t2-world-Terms-of-Service-3b251c65386b40349b5930337f2a714c',
        privacyPolicyUrl:
          'https://t2world.notion.site/t2world/t2-world-Privacy-Policy-5973f6c615a946b7ac8b543f2e03a2e7',
      },
      captchaEnabled: true,
    }}
  >
    {children}
  </OriginalPrivyProvider>
);
